<template>
  <div class="relative">
    <div
      ref="select"
      class="custom-select selector relative w-full flex cursor-pointer bg-white rounded-md rounded-b-0"
      :class="{
        'after:top-3 after:translate-y-0': smallSelect,
        'after:top-1/2 after:-translate-y-1/2 h-40': !smallSelect,
        'after:rotate-180 after:mr-2': open && upwardsSelect,
        'sm:h-48': xlargeSelect
      }"
      @blur="open = false"
    >
      <div
        class="selected cursor-pointer flex justify-between pr-28 bg-white rounded-md cursor-pointer w-full text-black items-center pl-12 border-grey400 border"
        :class="{
          'open': open,
          'rounded-b-none': open && !upwardsSelect,
          'rounded-t-none': open && upwardsSelect,
          'h-24': smallSelect,
        }"
        @click="open = !open"
      >
        <div v-if="icon || label" class="flex items-center">
          <DynamicIcon
            :icon-name="icon"
            class="w-16 h-16 mr-8 sm:h-18 sm:w-18"
          />
          <div class="text-small-medium mt-2">{{ label }}</div>
        </div>
        <div
          class="mt-3"
          :class="{
            'text-xsmall': smallSelect,
            'text-xsmall-medium md:text-small-medium': !smallSelect && !largeSelect,
            'text-small-medium': largeSelect
          }"
        >
          <span v-if="selected?.value">{{ selected?.name || placeholder || sharedResources.genericSelectorPlaceholder }}</span>
          <span v-else="selected?.value">{{ placeholder || sharedResources.genericSelectorPlaceholder }}</span>
        </div>
      </div>
      <div
        class="items z-10 left-0 right-0 bg-white absolute border border-grey400 text-black"
        :class="{
          hidden: !open,
          'top-[22px]': smallSelect && !upwardsSelect,
          'top-[39px]': !smallSelect && !upwardsSelect,
          'bottom-[22px]': smallSelect && upwardsSelect,
          'bottom-[37px]': !smallSelect && upwardsSelect,
          'rounded-t-md': upwardsSelect,
          'rounded-b-md': upwardsSelect,
          'md:top-[44px]': quantitySelect,
          'top-[47px]': xlargeSelect && !upwardsSelect,
        }"
      >
        <div
          v-for="(option, i) of items"
          :key="i"
          class="cursor-pointer flex justify-between pr-28 pl-12 bg-white cursor-pointer w-full text-black items-center border-b border-grey400 animate-all hover:bg-grey200"
          tabindex="0"
          :class="{
            'h-24': smallSelect,
            'h-40': !smallSelect,
            'last:border-none first:rounded-t-md': upwardsSelect,
            'last:border-none': !upwardsSelect,
            'sm:h-48': xlargeSelect
          }"
          @click="
            selected = option;
            open = false;
            $emit('change', option);
          "
        >
          <div
            :class="{
              'text-xsmall': smallSelect,
              'text-small-medium': !smallSelect,
            }"
          >
            {{ option.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue';
import useDetectOutsideClick from '~/composables/useDetectOutsideClick';
import { useGlobalContentStore } from '~/store/globalContent';
import DynamicIcon from '~/components/DynamicIcon.vue';
const globalContentStore = useGlobalContentStore();

const sharedResources = ref(globalContentStore.sharedResources);
const selected = ref();
const open = ref();

const props = defineProps<{
  items: any,
  placeholder?: string,
  defaultOption?: object,
  smallSelect?: boolean,
  upwardsSelect?: boolean,
  quantitySelect?: boolean,
  icon?: string,
  label?: string,
  largeSelect?: boolean,
  xlargeSelect?: boolean,
}>();

onMounted(()=> {
  selected.value = props.defaultOption || null;
});

const select = ref();

watch(
  () => props.items,
  () => {
    if (props.items) {
      selected.value = null;
    }
  }
);

useDetectOutsideClick(select, () => { 
  open.value = false;
});

</script>

<style scoped lang="postcss">
.selector::after {
  content: '';
  background-image: url('/icons/chevron-down.svg');
  height: 18px;
  width: 20px;
  @apply right-4 pointer-events-none absolute bg-contain;
}
.selector--small-select {
  top: 3px !important;
  translate: none !important;
}
</style>
<style lang="postcss">
.single-product {
  .selector::after {
    @apply right-12;
  }
}
</style>
